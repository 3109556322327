import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        userLoaded: false,
        finishedOnboarding: false,
        user: 'default',
        session_id: 'default',
        story_id: 'unset',
        previous_story_id: null,
        token: null,
        isLoggedIn: false,
        name: 'No name',
        age: 0,
        language: 'English',
        credits: 0,
        privacy_policy_accepted: true,
        tts_voice: null
    }),
    actions: {
        
        async loadUser(uid, token){
            const headers = {'Authorization': `Bearer ${token}`}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/user/me`, { headers });
            
            if (response.ok){
                const data = await response.json()
                if (uid !== data.user_id){
                    console.log("Error: User ID does not match token uid")
                    console.log(uid)
                    console.log(data.user_id)
                    return;
                }    
                this.name = data.name;
                this.age = data.age;
                this.language = data.language;
                this.finishedOnboarding = data.onboarding_finished
                this.credits = data.story_credits
                console.log("User data loaded")
                this.userLoaded = true
                this.previous_story_id = data.last_story_id
                this.tts_voice = data.tts_voice
            } else {
                console.log("Error: Could not load user data")
                console.log(response)
            }
        },
        async updateUser(uid, token){
            const headers = {'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/user/${uid}`, 
                { 'headers': headers,
                 'method': 'PUT',
                 'body': JSON.stringify({
                    name: this.name,
                    age: this.age,
                    language: this.language,
                    onboarding_finished: this.finishedOnboarding,
                    privacy_policy_accepted: this.privacy_policy_accepted,
                    tts_voice: this.tts_voice})
                })
            if (response.ok){
                console.log("User data updated")
            } else {
                console.log("Error: Could not update user data")
                console.log(response)
            }
        },
        async updateStoryId(uid, token){
            const headers = {'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/user/${uid}`, 
                { 'headers': headers,
                 'method': 'PUT',
                 'body': JSON.stringify({
                    last_story_id: this.story_id})
                })
            if (response.ok){
                console.log("User data updated")
            } else {
                console.log("Error: Could not update user data")
                console.log(response)
            }
        },
        login(user, token) {
            this.user = user
            this.token = token
            this.isLoggedIn = true
        },
        logout() {
            this.user = null
            this.token = null
            this.isLoggedIn = false
        },
        setStoryId(storyId) {
            this.story_id = storyId
        },
        setSessionId(sessionId) {
            this.session_id = sessionId
        },
        newStory() {
            this.story_id = null
        }
    },
    getters: {
        getUser() {
            return this.user
        },
        getToken() {
            return this.token
        },
        getIsLoggedIn() {
            return this.isLoggedIn
        },
        getName() {
            return this.name
        },
        getAge() {
            return this.age
        },
        getLanguage() {
            return this.language
        },
        getStoryId() {
            return this.story_id
        },
        getSessionId() {
            return this.session_id
        },
    },
})