<template>
    <div class="fullscreen-overlay" id="webcam-ui">
        <!-- the ref attribute is very important -->
        <div class="text-right">
            <div @click="this.$router.push(`/dashboard`)">
                <v-btn
                    class="ma-2"
                    color="red"
                    icon="mdi-arrow-left"
                    size="small"
                ></v-btn> 
                <!-- <button class="camera_shutter"></button> -->
            </div>
        </div>
        <WebCamBasic ref="webcam" @photoTaken="photoTakenEvent" @init="webcamInit"/>
        <div class="fullscreen-ui" style="background: rgba(0,0,0,0.4);">
            <div class="flex items-center justify-center h-full">
                    <div class="d-flex ">
                        <v-row>
                            <v-col>

                            </v-col>
                            <v-col>
                                <div @click="takePhoto">
                                <v-btn
                                    class="ma-2"
                                    color="red"
                                    icon="mdi-camera"
                                    size="x-large"
                                ></v-btn>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="align-self-center" @click="nextCamera">
                                    <v-btn
                                        class="ma-5"
                                        color="red"
                                        icon="mdi-swap-horizontal"
                                        size="40"
                                    ></v-btn> 
                                    <!-- <button class="camera_shutter"></button> -->
                                </div>

                            </v-col>  
                        <!-- <button class="camera_shutter"></button> -->
                        </v-row>  
                    </div>
            </div>
        </div>
    </div>
</template>


<style>
.camera_shutter {
    background-color: white; 
    border: none; 
    color: black; 
    text-align: center; 
    display: inline-block; 
    border-radius: 50%; 
    width: 50px; 
    height: 50px;
    margin-top: 10px;
}

.fullscreen-ui {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 120px;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.camera-success {
    background-color: green-500;
}

.camera-failed {
    border-color: red-500;
}

.button-control {
    padding: 0.5rem 1rem;
}

.invisible {
    visibility: hidden;
}
</style>

<script>
/* eslint-disable */
import WebCamBasic from './WebCamBasic.vue'
export default {
    data() {
        return {
            cameras: [],
            deviceId: '',
        }
    },
    components: {
        WebCamBasic
    },
    methods: {
        async takePhoto() {
            try {
                await this.$refs.webcam.takePhoto();
            } catch (err) {
                console.log(err)
            }
        },
        async nextCamera() {
            await this.$refs.webcam.nextCamera()
        },
        loadCameras() {
            this.$refs.webcam.loadCameras()
            this.cameras = this.$refs.webcam.cameras;
        },
        webcamInit(deviceId) {
            this.deviceId = deviceId
            this.$emit('init', this.deviceId)
        },
        setCamera() {
            this.$refs.webcam.changeCamera(this.deviceId === '' ? null : this.deviceId)
        },

        photoTakenEvent({ blob, image_data_url }) {
            this.$emit('photoTaken', { blob, image_data_url })
        },
    },
    // load cameras
    mounted () {
        // this.$refs.webcam.loadCameras()
        this.cameras = this.$refs.webcam.cameras;
        if (this.cameras.length === 0) {
            // if no camera found, we will try to refresh cameras list each second until there is some camera
            let reloadCamInterval = setInterval(() => {
                this.loadCameras()
                if (this.cameras.length > 0) {
                    clearInterval(reloadCamInterval)
                }
            }, 1000);
        }
    },
}
</script>
