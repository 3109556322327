import { createApp } from 'vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { VueFire, VueFireAuth } from 'vuefire'
import { createPinia } from 'pinia'
import { createWebHashHistory, createRouter } from 'vue-router'
import { initializeApp } from 'firebase/app'


import App from './App.vue'
// import VueCameraLib from 'vue-camera-lib'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
      },
  })

import UserIntro from './components/UserIntro.vue'
import StoryReader from './components/StoryReader.vue'
import MainCamView from './components/MainCamView.vue'
import LoginView from './components/LoginView.vue'
import SignUpView from './components/SignUpView.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import DashboardView from './components/DashboardView.vue'
import SettingsView from './components/SettingsView.vue'
import HistoryView from './components/HistoryView.vue'
import HistoryElementView from './components/HistoryElementView.vue'
import FeedbackView from './components/FeedbackView.vue'
import ProcessingView from './components/ProcessingView.vue'
import StoryFinishView from './components/StoryFinishView.vue'

const routes = [
    { path: '/', redirect: {path: '/dashboard'}},
    { path: '/login', component: LoginView, meta: {authNeeded: false}},
    { path: '/onboarding', component: UserIntro, meta: {authNeeded: true}},
    { path: '/signup', component: SignUpView, meta: {authNeeded: false}},
    { path: '/forgotpassword', component: ForgotPassword, meta: {authNeeded: false}},
    { path: '/dashboard', component: DashboardView, meta: {authNeeded: true}},
    { path: '/settings', component: SettingsView, meta: {authNeeded: true}},
    { path: '/history',  component: HistoryView, meta: {authNeeded: true}},
    { path: '/history/:storyId', name: 'historyElement', component: HistoryElementView, props: true, meta: {authNeeded: true}},
    { path: '/camera', component: MainCamView, meta: {authNeeded: true}},
    { path: '/processing/:imageData', name: 'processing', component: ProcessingView, props:true, meta: {authNeeded: true}},
    { path: '/story/:storyData', name: 'story', component: StoryReader, props: true, meta: {authNeeded: true}},
    { path: '/storyfinish/:storyMorale', name: 'storyfinish', component: StoryFinishView, props: true, meta: {authNeeded: true}},
    { path: '/feedback', component: FeedbackView, meta: {authNeeded: true}},
]



const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

const pinia = createPinia()

// const firebaseConfig = {
//   apiKey: "AIzaSyDermMOxio_CQZX9489_pMGXOsBxBdAjZY",
//   authDomain: "playmagine-dev.firebaseapp.com",
//   projectId: "playmagine-dev",
//   storageBucket: "playmagine-dev.appspot.com",
//   messagingSenderId: "356809122912",
//   appId: "1:356809122912:web:f3a9acc7f0318774dacf05",
//   measurementId: "G-SQ004X1NY0"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAOMdDqgPaNOGYBdOTuJfR1uq0z_7-6i-M",
  authDomain: "playmagine-bf65a.firebaseapp.com",
  projectId: "playmagine-bf65a",
  storageBucket: "playmagine-bf65a.appspot.com",
  messagingSenderId: "754800395427",
  appId: "1:754800395427:web:41f90a8a071b712f322b16",
  measurementId: "G-D85JL4CPS3"
};

export const firebaseApp = initializeApp(firebaseConfig)

import { getCurrentUser } from 'vuefire'


router.beforeEach(async (to) => {
  if (to.meta.authNeeded) {
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      }
    }
  }
})


const app = createApp(App)
app.use(pinia)
app.use(VueFire, {firebaseApp, modules: [VueFireAuth({persistence: ['indexedDBLocal']})]})
app.use(router)
app.use(vuetify)
app.mount('#app')
