<template>
    <v-container class="mb-auto">
        <v-sheet v-if="store.credits > 0" class="d-flex justify-center align-start ma-6">
                <v-btn size="80" class="mx-4" color="red" icon="mdi-camera" @click="startNewStory"></v-btn>
                <v-sheet class="fredoka-font mx-2 align-self-center">
                    Start Your Story
                    <p class="text-body-2">({{ store.credits }} credits left)</p>
                </v-sheet>   
        </v-sheet>
        <v-sheet v-else class="d-flex justify-center align-start ma-6">
                <v-btn size="80" class="mx-4" color="yellow" icon="mdi-cart" @click="getMoreCredits"></v-btn>
                <v-sheet class="fredoka-font mx-2 align-self-center">
                    <p class="fredoka-font">Get more credits to start new stories!</p>
                </v-sheet>
        </v-sheet>
        
        <v-sheet v-if="unfinishedStories.length > 0" class="mt-4 fredoka-font">
            Continue your stories:
        </v-sheet>
        <v-slide-group show-arrows center-active>

            <v-slide-group-item v-for="story in unfinishedStories" :key="story._id" v-slot="{ toggle }" >
                <v-scale-transition>
                <v-card @click="toggle" class="flex justify-center ma-2"   width="90%">
                    <v-img class="ma-2" max-height="200" :src="getImage(story.story_thumbnail)"></v-img>
                    <p class="ma-2">{{ story.title }}</p>
                    <v-btn size="small" class="my-2" color="red" @click="continueStory(story._id)">continue</v-btn>
                </v-card>
                </v-scale-transition>
            </v-slide-group-item>
        </v-slide-group>

        <v-sheet class="pa-4">
            <v-btn class="mt-2 fredoka-font" size="large" prepend-icon="mdi-history" @click="this.$router.push('/history')">Your Story History</v-btn>
        </v-sheet>
        
        
    </v-container>
</template>



<script>
import { useUserStore } from '@/stores/user'
import { useFirebaseAuth } from 'vuefire'
import { getCurrentUser } from 'vuefire'

export default {
    data() {
        return {
            store: useUserStore(),
            auth: useFirebaseAuth(),
            unfinishedStories: [],
        };
    },
    mounted: async function() {
        this.$emit("layoutSettings", {
                    showLogo: true,
                    title: null,
                    backButtonLink: null,
                    settings: true,
                })
        
        this.user = await getCurrentUser()
        await this.user.getIdToken().then(token => {
            this.store.loadUser(this.user.uid, token).then(() => {
            // if (!this.store.finishedOnboarding){
            //     this.$router.push('/onboarding')
            // }
            this.loadUnfinishedStories()
            })
        })        
    },
    unmounted: function() {
        this.$emit("layoutSettings", {
            showLogo: true,
            title: null,
            backButtonLink: null,
            settings: false,
        })
    },
    methods: {
        getImage(imageUrl) {
            return `${process.env.VUE_APP_API_URL}/image/${imageUrl}`
        },
        startNewStory() {
            this.store.newStory()
            this.$router.push('/camera')
        },
        continueStory(storyId) {
            this.store.story_id = storyId
            // this.$router.push('/camera')
            this.$router.push({name:'processing', params: {imageData: "empty"}})
        },
        async loadUnfinishedStories() {
            const token = await this.user.getIdToken()
            const headers = {'Authorization': `Bearer ${token}`}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/stories/stories?finished=false&limit=3`, { headers });
            this.unfinishedStories = await response.json()
            // console.log(this.unfinishedStories)

        }

    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
