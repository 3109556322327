<template>
  <v-container>
    <div class="text-justify" style="font-size: 0.75rem;">
        <p class='ma-4' v-for="paragraph in policyText" :key="paragraph">
            {{ paragraph }}
        </p>
    </div>
        

  </v-container>
</template>

<script>
import pp from 'raw-loader!@/assets/pp.txt'


export default {
  name: "PrivacyPolicy",
  data: () => ({
        policyText: pp.split("\n")
  })
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
