<template>
    <v-container class="mb-auto">
        <v-row v-if="error">
            <v-col>
                <v-alert
                    color="error"
                    icon="$error"
                    title="Error"
                    :text="error"
                    >
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="info">
            <v-col>
                <v-alert
                    color="info"
                    icon="$info"
                    title="Information"
                    :text="info"
                    >
                </v-alert>
            </v-col>
        </v-row>

        <v-row class="mx-auto">
            <v-col>
                <div class="text-h5 fredoka-font">Forgot your password?</div> 
                <p>Enter your email address below to reset your password.</p>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <v-form @submit.prevent="resetPassword">
                <v-text-field variant="underlined" v-model="email" label="Email Adress"></v-text-field>
                <v-btn class="mt-2 bg-red fredoka-font" size="large" type="submit" block>Reset Password</v-btn>
                </v-form>
            </v-col>
        </v-row>     
        <v-row class="mt-4">
            <v-col>
                <RouterLink class="text-red" to="/login">Back to login</RouterLink>
            </v-col>
        </v-row>    
    </v-container>
</template>

<script>
import { useFirebaseAuth } from 'vuefire'
import {
    sendPasswordResetEmail
} from 'firebase/auth'

export default {
    data() {
        return {
            auth: useFirebaseAuth(),
            error: null,
            info: null,
            email: null
        };
    },
    methods: {
        resetPassword() {
            sendPasswordResetEmail(this.auth, this.email)
            .then(() => {
                this.error = null
                this.info = 'Password reset email sent'
                // this.$router.push('/login')
            })
            .catch((error) => {
                console.log(error)
                this.error = error.message
                this.info = null
            });
        }
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
