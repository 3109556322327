<template>
  <v-responsive class="border rounded nunito-font">
    <v-app>

      <v-app-bar v-if="!hideTopBar" :class="barClass"  :elevation="0"  max-width="300">
        <template v-slot:image>
          <v-img v-if="showLogo" max-height="70" :src="require('@/assets/logo2.jpg')"
          ></v-img>
        </template>

        <!-- <template v-slot:prepend>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </template> -->
        <template v-slot:prepend>
          <v-app-bar-nav-icon 
            v-if="backButtonLink" 
            icon="mdi-arrow-left"
            @click="this.$router.push(backButtonLink)"
          >
          </v-app-bar-nav-icon>
        </template>
        <v-app-bar-title v-if="title" class="fredoka-font text-h5 text-left">
          {{ title }}
        </v-app-bar-title> 
        <template v-slot:append>
          <v-btn v-if="settings" icon="mdi-cog" @click="this.$router.push('/settings')"></v-btn>
        </template>
      </v-app-bar>


      <!-- <v-navigation-drawer>
        <v-list-item title="Test navigation" subtitle="Vuetify"></v-list-item>
        <v-divider></v-divider>
        <v-list-item><RouterLink to="/">Home</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/login">Login</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/signup">Sign Up</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/onboarding">Onboarding</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/forgotpassword">Forgot Password</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/camera">Camera</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/story">Story</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/dashboard">Dashboard</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/feedback">Feedback</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/history">History</RouterLink></v-list-item>
        <v-list-item><RouterLink to="/settings">Settings</RouterLink></v-list-item>
        <v-list-item><v-btn @click="this.$router.push({name: 'storyfinish', params: {storyMorale: 'It is good to be kind!'}})">Finish</v-btn></v-list-item>
        
      </v-navigation-drawer> -->
    
      <v-main class="d-flex align-center fill-height" style="min-height: 200px;">
        <RouterView @layoutSettings="updateLayoutSettings"/>        
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script>
// import MainCamView from './components/MainCamView.vue'
// import UserIntro from './components/UserIntro.vue'
// import StoryReader from './components/StoryReader.vue'

import { useFirebaseAuth } from 'vuefire'
// import { useCurrentUser } from 'vuefire'
import { getCurrentUser } from 'vuefire'
// import { onAuthStateChanged } from "firebase/auth"
import { useUserStore } from '@/stores/user'


export default {
  name: 'App',
  components: {
    // MainCamView,
    // UserIntro,
    // StoryReader
  },
  data() {
    return {
      state: 'user_intro',
      last_photo_base64: null,
      session_id: null,
      userData: null,
      currentStoryData: null,
      showLogo: true,
      title: null,
      hideTopBar: false,
      backButtonLink: null,
      barClass: 'bg-red',
      auth: useFirebaseAuth(),
      isLoggedIn: false,
      user: null,
      settings: false,
      store: useUserStore(),

    }
  },
  async mounted() {
      this.user = await getCurrentUser()

      if(!this.user){
        this.$router.push('/login')
      } else {
        console.log('user:', this.user)
        this.user.getIdToken().then(token => {
          console.log(token)
          this.store.loadUser(this.user.uid, token)
        })
      }
  },
  created() {

  },
  methods: {
    updateLayoutSettings(data) {
      if (data.showLogo !== undefined) {
        this.showLogo = data.showLogo;
      }
      if (data.title !== undefined) {
        this.title = data.title;
      }
      if (data.hideTopBar !== undefined) {
        this.hideTopBar = data.hideTopBar;
      }
      if (data.backButtonLink !== undefined) {
        this.backButtonLink = data.backButtonLink;
      }
      if (data.barClass !== undefined) {
        this.barClass = data.barClass;
      }
      if (data.settings !== undefined) {
        this.settings = data.settings;
      }
    },
  }
}
</script>



<style> 
html {
  /* overflow: hidden !important; */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.fredoka-font {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  font-variation-settings:
    "width" 100;
}

.fredoka-font-small {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  text-transform: none;
  font-variation-settings:
    "width" 100;
  font-size: 1rem;
}

.nunito-font {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  text-transform: none;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  /* color: #2c3e50; */
}

.image-container {
  position: relative;
}

.spinner-overlay {
  position: relative;
  top: 50%;
  left: 25%;
  width: 50%;
  height: 50%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
