<template>
  <v-container>
    <div class="text-justify" style="font-size: 0.75rem;">
        <p class='ma-4' v-for="paragraph in termsText" :key="paragraph">
            {{ paragraph }}
        </p>
    </div>
        

  </v-container>
</template>

<script>
import terms from 'raw-loader!@/assets/terms.txt'


export default {
  name: "TermsOfUsage",
  data: () => ({
        termsText: terms.split("\n")
  })
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
