<template>
    <v-container v-if="disclaimerAccepted" class="mb-auto">
        <v-row class="mx-auto">
            <v-col>
                <div class="text-h5 fredoka-font">Begin Your Journey</div> 
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form @submit.prevent="submit">
                <v-text-field variant="underlined" v-model="name" label="Your Child's Name" required></v-text-field>
                <v-text-field variant="underlined" v-model="age" label="Age" type="number" required></v-text-field>
                <v-select variant="underlined" v-model="language" label="Language" :items="['English', 'German', 'Polish']">
                </v-select>
                <v-btn class="mt-2 bg-red fredoka-font" size="x-large" prepend-icon="mdi-compass"  block @click="sendUserData">Get Started</v-btn>
                </v-form>
            </v-col>
        </v-row> 
    </v-container>
    <v-container v-else class="mb-auto">
        <v-sheet>
            <p class="text-h4 ma-5 fredoka-font">Before You Start</p>
            <div class="text-left">
            <p class="ma-3">Playmagine is a storytelling app for children that uses photos to create stories.</p>
            <p class="ma-3">We collect photos, your child's first name and age, and device information.</p>
            <p class="ma-3">We use this data to improve the app and personalize stories.</p>
            <p class="ma-3">The data is stored on Amazon Web Services and shared with OpenAI for processing.</p>
            <p class="ma-3">You can manage your child's data and request its deletion at any time.</p>
            </div>
            <div class="ma-2">
                <v-btn class="bg-red mt-4 fredoka-font" size="large" block @click="disclaimerAccepted = true">I Acknowledge This</v-btn>
            </div>
            
        </v-sheet>
    </v-container>
</template>



<script>
import { useUserStore } from '@/stores/user'
import { getCurrentUser } from 'vuefire'

export default {
    data() {
        return {
            store: useUserStore(),
            name: '',
            age: null,
            language: 'English',
            user: null,
            disclaimerAccepted: false
        };
    },
    mounted: async function() {
        this.user = await getCurrentUser()
        await this.user.getIdToken().then(token => {
            this.store.loadUser(this.user.uid, token)
        })        
    },

    methods: {
        submit(){

        },
        async sendUserData() {
            console.log('sending user data')
            // Emit the user data to the parent component
            this.store.name = this.name
            this.store.age = this.age
            this.store.language = this.language
            this.store.finishedOnboarding = true

            this.user = await getCurrentUser()
            await this.user.getIdToken().then(token => {
                this.store.updateUser(this.user.uid, token)
            })

            this.$router.push('/dashboard')
        }
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
