<template>
    <!-- <div>
        <h1>{{ title }}</h1>

        <audio :src="audio_stream" controls ref="audio"></audio>
        
        <p>{{ storyText }}</p>


        <div v-if="isFinal">
            <h2>Congratulations! You have completed the story.</h2>
            <button @click="$emit('continueStory')">Start new story!</button>    
        </div>
        <div v-else>
            <h2>Question</h2>
            <p>{{ question }}</p>
            <h2>Task</h2>
            <p>{{ storyTask }}</p>
            <button @click="$emit('continueStory')">Continue</button>
        </div>
    </div> -->
    <v-container class="mb-auto">
        <div class="rounded-circle bg-yellow mx-auto mt-8" style="height: 150px;width: 150px">
            <v-icon class="mt-2" icon="mdi-medal" size="140" color="white">
            </v-icon>
        </div>
        <!-- <v-sheet class="mt-4 justify-center">
            <p class="align-self-center ma-4" >Never forget:<br>{{ storyMorale }}</p>
        </v-sheet> -->
        <v-sheet class="mt-16">
            <v-btn
                class="my-4 mx-1 align-self-center"
                color="red"
                prepend-icon="mdi-camera"
                size="large"
                block
                @click="startNewStory"
            >Start a New Story</v-btn>          
            <v-btn
                class="my-4 mx-1 align-self-center bg-grey-lighten-2"
                color="red"
                append-icon="mdi-chat"
                size="large"
                block
                @click="this.$router.push('/feedback')"
            >Submit Feedback</v-btn>          
        </v-sheet>
        <v-bottom-navigation class="bg-grey-lighten-2">
        <v-btn value="home" prepend-icon="mdi-home" @click="this.$router.push('/dashboard')">
            <span>Return Home</span>
        </v-btn>
        </v-bottom-navigation>
            </v-container>
</template>

<script>
import { useUserStore } from '@/stores/user'

export default {
    props: ["storyMorale"],
    data() {
        return {
            store: useUserStore()
        };
    },
    mounted: function() {
        this.$emit("layoutSettings", {
            hideTopBar: false,
            showLogo: false,
            title: "You Did It!",
            backButtonLink: "/dashboard",
            barClass: 'bg-white'
        })
    },
    unmounted: function() {
        this.$emit("layoutSettings", {
            hideTopBar: false,
            barClass: 'bg-red'
        })
    },
    methods: {
        startNewStory() {
            this.store.newStory()
            this.$router.push('/camera')
        },
    }
};
</script>

<style scoped>
h1 {
    font-size: 24px;
    font-weight: bold;
}

p {
    font-size: 16px;
    margin-top: 10px;
}
</style>
