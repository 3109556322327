<template>
    <v-container class="mb-auto">
        
        <v-sheet>
            <!-- <v-img class="rounded-xl" :src="require('@/assets/testimage.png')"></v-img> -->
            <v-img class="rounded-xl" :src="getImage(imgUrl)"></v-img>
        </v-sheet>



        <v-sheet class="mt-4">
            <audio  controls ref="audio">
                <source :src="audio_stream" type="audio/mp4">
            </audio>    
        </v-sheet>  
        <v-sheet v-if="!isFinal" class="d-flex justify-center">
            <p class="align-self-center ma-4">Continue Your Story</p>
            <v-btn
                class="ma-2 align-self-center"
                color="red"
                icon="mdi-camera"
                size="x-large"
                @click="this.$router.push('/camera')"
            ></v-btn>          
        </v-sheet>
        <v-sheet v-else class="d-flex justify-center">
            <p class="align-self-center ma-4">Claim your reward</p>
            <v-btn
                class="ma-2 align-self-center"
                color="yellow"
                icon="mdi-medal"
                size="x-large"
                @click="this.$router.push({name: 'storyfinish', params: {storyMorale: this.morale}})"
            ></v-btn>          
        </v-sheet>
        <v-expansion-panels class="mt-4 text-justify">
            <v-expansion-panel
                title="Story"
            >
                <v-expansion-panel-text>
                    <p>{{ storyText }}</p>
                    
                    <p v-if="!isFinal">{{ question }}</p>
                    <!-- <p v-if="!isFinal">{{ storyTask }}</p> -->
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    props: ["storyData"],
    data() {
        return {
            title: this.storyData.title,
            storyText: this.storyData.text,
            question: this.storyData.question,
            // storyTask: this.storyData.task,
            isFinal: this.storyData.isFinal,
            imgUrl: this.storyData.img_url,
            // morale: this.storyData.morale,
            morale: "",
            audio_stream: `${process.env.VUE_APP_API_URL}/stream/${this.storyData.audio_stream_id}`
        };
    },
    mounted: function() {
        console.log(this.storyData)
        this.$emit("layoutSettings", {
            hideTopBar: false,
            showLogo: false,
            title: this.title,
            backButtonLink: "/dashboard",
            barClass: 'bg-white'
        })
        
        this.$refs.audio.play();
    },
    unmounted: function() {
        this.$emit("layoutSettings", {
            hideTopBar: false,
            barClass: 'bg-red'
        })
    },
    methods: {
        getImage(imageUrl) {
            return `${process.env.VUE_APP_API_URL}/image/${imageUrl}`
        },
    }
};
</script>

<style scoped>
h1 {
    font-size: 24px;
    font-weight: bold;
}

p {
    font-size: 16px;
    margin-top: 10px;
}
</style>
