<template>
    <v-container v-if="!storyData">
        <v-progress-circular
        color="red"
        size="250"
        indeterminate
        >
            <template v-slot:default>
                <v-icon 
                    color="red"
                    icon="mdi-auto-fix"
                    size="80"
                    ></v-icon>        
            </template>
        </v-progress-circular>
    </v-container>

    <StoryReader v-else :storyData="storyData"/>
</template>



<script>
import { useUserStore } from '@/stores/user'
import StoryReader from './StoryReader.vue'
import { getCurrentUser } from 'vuefire'

export default {
    props: ["imageData"],
    components: {
        StoryReader
    },
    data() {
        return {
            store: useUserStore(),
            storyData: null,
            user: null
        };
    },
    mounted: async function() {
        this.$emit("layoutSettings", {
            hideTopBar: true
        })
        this.user = await getCurrentUser()

        if (!this.store.userLoaded){
            this.user.getIdToken().then(token => {
                this.store.loadUser(this.user.uid, token)
            })    
        }

        this.user.getIdToken().then(token => {
            if(this.imageData !== "empty"){
                this.processNewPhoto(this.imageData, token)
            } else {
                this.getExistingStory(this.store.story_id, token)
            }
        })
        
    },
    unmounted: function() {
        this.$emit("layoutSettings", {
            hideTopBar: false
        })
    },
    methods: {
        processNewPhoto(data, token) {
            this.uploadPhoto(data, token)
        },
        async uploadPhoto(data, token) {
            // remove the data:image/jpeg;base64, prefix
            var photo_str = data.split("base64,")[1];
            try {
                const headers = {'Authorization': `Bearer ${token}`}
                const response = await fetch(`${process.env.VUE_APP_API_URL}/image_string`, {
                    method: 'POST',
                    headers: headers,
                    body: photo_str
                });

                if (response.ok) {
                    // Photo uploaded successfully
                    console.log('Photo uploaded successfully');
                    const response_data = await response.json();
                    console.log('response_data:', response_data);
                    const photo_id = response_data['image_id']
                    this.getStoryWithPhoto(photo_id, token)
                } else {
                // Error uploading photo
                console.error('Error uploading photo');
                this.$router.push('/dashboard')
                }
            } catch (error) {
                console.error('Error uploading photo:', error);
                this.$router.push('/dashboard')
            }
        
        },
        async getStoryWithPhoto(image_id, token){
            console.log('getStoryWithPhoto:', image_id)
            const requestBody = {
                "image_id": image_id,
                "image_description": "<no description>"
            };

            try {
                var request_url = ""
                if (this.store.story_id) {
                    // existing story
                    if(this.store.story_id === "unset"){
                        this.store.story_id = this.store.previous_story_id
                    }
                    request_url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_STORY_API_VERSION}/story_fragment/${this.store.story_id}`
                }
                else {
                    // new story
                    request_url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_STORY_API_VERSION}/story`
                }
                const response = await fetch(request_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)
                });

                if (response.ok) {
                // Handle successful response
                console.log('Story fragment retrieved successfully');
                const response_data = await response.json();
                console.log('response_data:', response_data);

                // this.$router.push({name:'story', params: {storyData: response_data}})
                this.storyData = response_data
                this.store.story_id = response_data['story_id']
                this.store.updateStoryId(this.user.uid, token)

                } else {
                    // Handle error response
                    console.error('Error retrieving story fragment');
                    this.$router.push('/dashboard')
                }
            } catch (error) {
                    console.error('Error retrieving story fragment:', error);
                    this.$router.push('/dashboard')
            }
        
        },
        async getExistingStory(story_id, token){
            var request_url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_STORY_API_VERSION}/story_fragment/${story_id}`
            const response = await fetch(request_url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });   
            if (response.ok) {
                // Handle successful response
                console.log('Story fragment retrieved successfully');
                const response_data = await response.json();
                console.log('response_data:', response_data);

                // this.$router.push({name:'story', params: {storyData: response_data}})
                this.storyData = response_data
                this.store.story_id = response_data['story_id']

            } else {
                // Handle error response
                console.error('Error retrieving story fragment');
                this.$router.push('/dashboard')
            }
        }
        
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
