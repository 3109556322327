<template>
  <!-- <WebCamUI :fullscreenState="true" @photoTaken="photoTaken" /> -->
  <CustomWebCamUI v-if="!imageData" :fullscreenState="false" @photoTaken="photoTaken" />
  <v-container v-else class="mb-auto">
    <v-sheet>
      <v-img :src="imageData"/>
      <v-btn
          class="ma-2"
          color="red"
          icon="mdi-check"
          size="x-large"
          @click="this.$router.push({name:'processing', params: {imageData: imageData}})"
      ></v-btn> 
      <v-btn
          class="ma-2"
          color="red"
          icon="mdi-replay"
          size="x-large"
          @click="imageData = null"
      ></v-btn> 
    </v-sheet>
  </v-container>

</template>

<script>
// import { WebCamUI } from 'vue-camera-lib'
import CustomWebCamUI from './CustomWebCamUI.vue'

export default {
  name: 'MainCamView',
  components: {
    // WebCamUI
    CustomWebCamUI
  },
  data() {
    return {
      imageData: null
    }
  },
  mounted() {
    console.log('MainCamView mounted')
    this.$emit('layoutSettings', { hideTopBar: true})    
  },
  unmounted() {
    console.log('MainCamView unmounted')
    this.$emit('layoutSettings', { hideTopBar: false})
  },
  methods: {
    photoTaken(data) {
      this.$emit('lastPhoto', data.image_data_url)
      this.imageData = data.image_data_url
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
