<template>
    <v-container class="mb-auto">
        <v-sheet v-if="selectedElement === null">            
            <v-row v-for="story in finishedStories" :key="story._id" dense>
                <v-col cols="12">
                    <v-card @click="selectedElement = story._id">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-avatar class="ma-3" rounded="0" size="100">
                                <v-img :src="getImage(story.story_thumbnail)"></v-img>
                            </v-avatar>
                            <div class="text-left">
                                <v-card-title class="fredoka-font-small text-wrap">
                                    {{ story.title }}
                                </v-card-title>
                                <v-card-subtitle>{{ formatDate(story.completion_timestamp) }}</v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-sheet>
    </v-container>
</template>



<script>
import { getCurrentUser } from 'vuefire'

export default {
    data() {
        return {
            selectedElement: null,
            finishedStories: [],
            user: null

        };
    },
    mounted: async function() {
        this.$emit("layoutSettings", {
            showLogo: false,
            title: "History",
            backButtonLink: "/dashboard"
        })
        this.user = await getCurrentUser()
           
        await this.loadFinishedStories()
        
    },
    watch: {
        selectedElement(val) {
            console.log(val)
            this.$router.push({name:'historyElement', params: {storyId: val}})
        }
    },
    methods: {
        formatDate(unixTimestamp) {
            const date = new Date(unixTimestamp * 1000)
            return date.toLocaleDateString()
            
        },
        async loadFinishedStories() {
            const token = await this.user.getIdToken()
            const headers = {'Authorization': `Bearer ${token}`}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/stories/stories?finished=true&limit=10`, { headers });
            this.finishedStories = await response.json()
            // console.log(this.finishedStories)

        },
        getImage(imageUrl) {
            return `${process.env.VUE_APP_API_URL}/image/${imageUrl}`
        },


    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
