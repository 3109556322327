<template>
    <v-container class="mb-auto">
        <v-sheet>
            <v-card class="text-left">
                <v-card-title class="text-center">
                    <v-avatar
                            class="ma-3"
                            rounded="0"
                            size="200"
                            >
                            <v-img v-if="story" :src="getImage(story.story_thumbnail)"></v-img>
                            </v-avatar>
                </v-card-title>
                <v-card-text>
                    
                </v-card-text>
                <v-card-subtitle>
                    Story summary
                </v-card-subtitle>
                <!-- <v-card-text v-if="story">
                    {{ story.outline.world }}
                </v-card-text>
                <v-card-subtitle>
                    Morale of the story
                </v-card-subtitle>
                <v-card-text v-if="story">
                    {{ story.outline.morale }}
                </v-card-text>
                <v-card-subtitle>
                    Main Characters
                </v-card-subtitle>
                <v-card-text v-if="story">
                    <p v-for="character in story.outline.characters" :key="character" class="ma-2 text-wrap">
                        {{ character }}
                    </p>
                </v-card-text> -->

                <v-expansion-panels class="mt-4">
                    <v-expansion-panel
                        title="Debug"
                    >
                        <v-expansion-panel-text>
                            <pre class="text-body-2">{{ story }}</pre>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </v-sheet>
    </v-container>
</template>



<script>
import { getCurrentUser } from 'vuefire'

export default {
    props: {
        storyId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            user: null,
            story: null
        };
    },
    mounted: async function() {

        this.user = await getCurrentUser()
        await this.loadStory(this.storyId)
        this.$emit("layoutSettings", {
            showLogo: false,
            title: this.story.title,
            backButtonLink: "/history"
        })
    },
    methods: {
        submit() {
            
        },
        getImage(imageUrl) {
            return `${process.env.VUE_APP_API_URL}/image/${imageUrl}`
        },
        async loadStory(storyId) {
            const token = await this.user.getIdToken()
            const headers = {'Authorization': `Bearer ${token}`}
            const response = await fetch(`${process.env.VUE_APP_API_URL}/stories/story/${storyId}`, { headers });
            this.story = await response.json()
            console.log(this.story)

        }
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
