<template>
    <v-container class="mb-auto">
        <v-row v-if="error">
            <v-col>
                <v-alert
                    color="error"
                    icon="$error"
                    title="Error"
                    :text="error"
                    >
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="warning">
            <v-col>
                <v-alert
                    color="warning"
                    icon="$warning"
                    title="Error"
                    :text="warning"
                    >
                </v-alert>
            </v-col>
        </v-row>

        <v-row class="mx-auto">
            <v-col>
                <div class="text-h5 fredoka-font">Create Your Account</div> 
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <v-form lazy-validation=true ref="signupform">
                <v-text-field variant="underlined" v-model="email" label="Email Address" type="email" :rules='emailRules' required></v-text-field>
                <v-text-field variant="underlined" v-model="password" label="Password" type="password" :rules='passwordRules' required></v-text-field>
                <!-- <v-checkbox v-model="privacyPolicy" label="I agree to the Privacy Policy" :rules='checkboxRules' required></v-checkbox> -->

                <v-checkbox v-model="consent" type="checkbox" label="checkbox" false-value="0" :rules='checkboxRules' required>
                    <template v-slot:label>
                        <div>
                        I consent to my child's use of this app and agree to the 
                        <!-- <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                            <a
                                href=""
                                v-bind="props"
                                @click.stop
                            >
                                Terms of Use
                            </a>
                            </template>
                            Preview Terms of Use
                        </v-tooltip> -->

                        <v-dialog max-width="600">
                        <template v-slot:activator="{ props: activatorProps }">
                            <a
                                href=""
                                @click.prevent
                                v-bind="activatorProps"
                                                       
                            >Terms of Use</a>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-sheet>  
                                <TermsOfUsage></TermsOfUsage>
                                <v-btn class="my-4 bg-red"
                                    block
                                    text="Close Terms of Use"
                                    @click="isActive.value = false"
                                ></v-btn>
                            </v-sheet>
                        </template>
                        </v-dialog>
                         and 
                        <v-dialog max-width="600">
                        <template v-slot:activator="{ props: activatorProps }">
                            <a
                                href=""
                                @click.prevent
                                v-bind="activatorProps"
                                                       
                            >Privacy Policy</a>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-sheet>  
                                <PrivacPolicy></PrivacPolicy>
                                <v-btn class="my-4 bg-red"
                                    block
                                    text="Close Privacy Policy"
                                    @click="isActive.value = false"
                                ></v-btn>
                            </v-sheet>
                        </template>
                        </v-dialog>

                        </div>
                    </template>
                </v-checkbox>
                <v-btn class="mt-2 bg-red fredoka-font" size="large" @click="registerViaEmail" block>Sign Up</v-btn>
                </v-form>
            </v-col>
        </v-row>   
        <!-- <v-row class="mt-6">
            <v-col>
                <p>or</p>
            </v-col>
        </v-row> -->
        <!-- <v-row>
            <v-col>
                <v-btn class="mt-0 bg-grey-darken-2 fredoka-font" size="large" block @click="signinRedirect()">SignIn with Google</v-btn>
            </v-col>
        </v-row>   -->
        <v-row class="mt-4">
            <v-col>
                <RouterLink class="text-red" to="/login">Back to login</RouterLink>
            </v-col>
        </v-row>   
        
    </v-container>
</template>

<script>

import { useFirebaseAuth } from 'vuefire'
import {
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { GoogleAuthProvider } from 'firebase/auth'
import PrivacPolicy from '@/components/PrivacyPolicy.vue'
import TermsOfUsage from '@/components/TermsOfUsage.vue'

export const googleAuthProvider = new GoogleAuthProvider() 

export default {
    components: {
        PrivacPolicy,
        TermsOfUsage
    },
    data() {
        return {
            consent: false,
            auth: useFirebaseAuth(),
            email: null,
            password: null,
            error: null,
            warning: null,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is reuqired',
                v => v && v.length >= 8 || 'Password must be at least 8 characters',               
            ],
            checkboxRules: [
                v => !!v || 'Please agree to the privacy policy']
        };
    },
    methods: {
        registerViaEmail() {
            // this.$refs.signupform.resetValidation()
            // const { valid } = this.$refs.signupform.validate()
            // if (!valid) {
            //     console.log('Form is not valid')
            //     return
            // }

            if (!this.consent) {
                this.warning = 'Please agree to the privacy policy'
                return
            }

            createUserWithEmailAndPassword(this.auth, this.email, this.password).then(
                (data) => {
                    console.log('User created', data)
                    this.$router.push('/onboarding')
                }
            ).catch((reason) => {
                console.error('Failed to create user', reason)
                switch (reason.code) {
                case 'auth/email-already-in-use':
                    this.error = 'Email already in use'
                    break
                case 'auth/admin-restricted-operation':
                    break
                default:
                    this.error = reason.message
                    break
                }
            })
        },
        signinRedirect() {
            if (!this.consent) {
                this.warning = 'Please agree to the privacy policy'
                return
            }
            signInWithRedirect(this.auth, googleAuthProvider).then(
                (data) => {
                    console.log('User created', data)
                    this.$router.push('/onboarding')
                }
            ).catch((reason) => {
            console.error('Failed signinRedirect', reason)
            })
        },
        signinPopup() {
            signInWithPopup(this.auth, googleAuthProvider).catch((reason) => {
                console.error('Failed sign', reason)
                this.error = reason.message
            })
        },
        submit() {
            
        }
    },
    mounted: function() {
        getRedirectResult(this.auth).catch((reason) => {
            console.error('Failed redirect result', reason)
            this.error = reason.message
            })
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
