<template>
    <v-container class="mb-auto">
        <v-sheet>
            Thank you for using Playmagine!<br>
            Could you spare a moment to share your feedback?
        </v-sheet>
        <div class="text-center">
            <v-rating
            v-model="rating"
            active-color="red"
            color="grey"
            size="x-large"
            density="comfortable"
            hover
            ></v-rating>
        </div>
        <v-sheet>
            <p>What did you like most about the app?</p>
        <v-textarea ref='likedMost' variant="outlined"></v-textarea>
        </v-sheet>

        <v-sheet>
            <p>What could we do to make the app even better?</p>
        <v-textarea ref='makeBetter' variant="outlined"></v-textarea>
        </v-sheet>

        <v-sheet class="">
            <v-btn class="fredoka-font bg-red" block @click="feedback">Submit Feedback</v-btn>
        </v-sheet>
                
        
    </v-container>
</template>



<script>

import { getCurrentUser } from 'vuefire'

export default {
    data() {
        return {
            rating: null,
            user: null,
        };
    },
    mounted: async function() {
        this.$emit("layoutSettings", {
            hideTopBar: false,
            showLogo: false,
            title: "We love Feedback!",
            backButtonLink: "/dashboard",
            barClass: 'bg-red'
        })

        this.user = await getCurrentUser()
    },
    methods: {
        async feedback() {
            const numberOfStars = this.rating;
            const likedMost = this.$refs.likedMost.value;
            const makeBetter = this.$refs.makeBetter.value;
            console.log(numberOfStars, likedMost, makeBetter);

            const requestBody = {
                score: numberOfStars,
                like: likedMost,
                better: makeBetter
            }

            await this.user.getIdToken().then(token => {
                const request_url = `${process.env.VUE_APP_API_URL}/user/feedback`
                const response = fetch(request_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)
                });
                console.log(response)
            })

            this.$router.push('/dashboard')
        }
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
