<template>
    <v-container class="mb-auto">
        <v-row v-if="error">
            <v-col>
                <v-alert
                    color="error"
                    icon="$error"
                    title="Error"
                    :text="error"
                    >
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="mx-auto">
            <v-col>
                <div class="text-h5 fredoka-font">Welcome Back</div> 
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <v-form @submit.prevent="submit">
                <v-text-field variant="underlined" v-model="email" label="Email Adress" :rules='emailRules' required></v-text-field>
                <v-text-field variant="underlined" v-model="password" label="Password" type="password" :rules='passwordRules' required></v-text-field>
                <p class="text-left"><RouterLink class='text-red font-weight-thin' to="/forgotpassword">Forgot password?</RouterLink></p>
                <v-btn class="mt-6 bg-red fredoka-font" size="large" type="submit" block @click="loginViaEmail" >Log In</v-btn>
                </v-form>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p>or</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn class="mt-0 fredoka-font" size="large" block @click="this.$router.push('/signup')">Sign Up</v-btn>
            </v-col>
        </v-row>
        
    </v-container>
</template>


<script>

import { useFirebaseAuth } from 'vuefire'
import {
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { GoogleAuthProvider } from 'firebase/auth'

export const googleAuthProvider = new GoogleAuthProvider() 

export default {
    data() {
        return {
            privacyPolicy: false,
            auth: useFirebaseAuth(),
            email: null,
            password: null,
            error: null,
            warning: null,
            emailRules: [
                v => !!v || 'E-mail is required',
            ],
            passwordRules: [
                v => !!v || 'Password is reuqired',
            ],
        };
    },
    methods: {
        loginViaEmail() {
           signInWithEmailAndPassword(this.auth, this.email, this.password).then(
                (data) => {
                    console.log('User logged in', data)
                    this.$router.push('/dashboard')
                }
            ).catch((reason) => {
                console.error('Failed to login', reason)
                switch (reason.code) {
                    case 'auth/invalid-email':
                        this.error = 'Invalid email'
                        break
                    case 'auth/user-not-found':
                        this.error = 'No account with that email was found'
                        break
                    case 'auth/wrong-password':
                        this.error = 'Incorrect password'
                        break
                    default:
                        this.error = 'Email or password was incorrect'
                        break
                }
            })
        },
        signinRedirect() {
            signInWithRedirect(this.auth, googleAuthProvider).catch((reason) => {
            console.error('Failed signinRedirect', reason)
            })
        },
        signinPopup() {
            signInWithPopup(this.auth, googleAuthProvider).catch((reason) => {
                console.error('Failed sign', reason)
            })
        },
        submit() {
            
        }
    },
    mounted: function() {
        getRedirectResult(this.auth).catch((reason) => {
            console.error('Failed redirect result', reason)
            })
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
