<template>
    <v-container class="mb-auto">
        <v-row v-if="error">
            <v-col>
                <v-alert
                    color="error"
                    icon="$error"
                    title="Error"
                    :text="error"
                    >
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="info">
            <v-col>
                <v-alert
                    color="info"
                    icon="$info"
                    title="Information"
                    :text="info"
                    >
                </v-alert>
            </v-col>
        </v-row>


        <v-expansion-panels>
            <v-expansion-panel
                title="Child's Information"
            >
            <v-expansion-panel-text>
                <v-form @submit.prevent="submit">
                <v-text-field variant="underlined" v-model="store.name" label="Your Child's Name"></v-text-field>
                <v-text-field variant="underlined" v-model="store.age" label="Age" type="number"></v-text-field>
                <v-select variant="underlined" v-model="store.language" label="Language" :items="['English', 'German', 'Polish']">
                </v-select>
                <v-select variant="underlined" v-model="store.tts_voice" label="Voice" :items="['echo', 'nova', 'shimmer']">
                </v-select>
                <v-btn class="mt-2 bg-red fredoka-font" type="submit" block>Save</v-btn>
                </v-form>
            </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Account Settings"
            >
                <v-expansion-panel-text>
                    <v-btn @click="resetPassword" block>Reset Password</v-btn>
                </v-expansion-panel-text>   
            </v-expansion-panel>
            <v-expansion-panel
                title="Credits">
                <v-expansion-panel-text>
                    <v-sheet class="ma-2">
                        Available story credits: <b>{{ store.credits }}</b>
                    </v-sheet>
                    <v-btn class="ma-2 bg-red">Get more credits</v-btn>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Privacy Policy">
                <v-expansion-panel-text>
                    <PrivacPolicy></PrivacPolicy>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Terms of Usage">
                <v-expansion-panel-text>
                    <TermsOfUsage></TermsOfUsage>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Contact Support">
                <v-expansion-panel-text>
                    <p>Write to us: <a href=mailto:contact@playmagine.xyz>contact@playmagine.xyz</a></p>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Feedback">
                <v-expansion-panel-text>
                    <v-btn @click="this.$router.push('/feedback')" block>Give Us Your Feedback!</v-btn>                
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
                title="Sign Out">
                <v-expansion-panel-text>
                    <v-btn @click="signOut" block>Sign Out Now</v-btn>                
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        
        

    </v-container>
</template>



<script>
import { useFirebaseAuth } from 'vuefire'
import { useUserStore } from '@/stores/user'
import { getCurrentUser } from 'vuefire'
import PrivacPolicy from '@/components/PrivacyPolicy.vue'
import TermsOfUsage from '@/components/TermsOfUsage.vue'
import {
    sendPasswordResetEmail
} from 'firebase/auth'

export default {
    components: {
        PrivacPolicy,
        TermsOfUsage
    },
    data() {
        return {
            auth: useFirebaseAuth(),
            store: useUserStore(),
            user: null,
            error: null,
            info: null
        };
    },
    mounted: async function() {
        this.$emit("layoutSettings", {
                    showLogo: false,
                    title: 'Grown-Up Stuff',
                    backButtonLink: '/dashboard',
                    settings: false,
                })
        this.user = await getCurrentUser()
        this.user.getIdToken().then(token => {
            this.store.loadUser(this.user.uid, token)
        })
    },
    methods: {
        signOut(){
            this.auth.signOut()
            this.$router.push('/login')
        },
        submit() {
            this.user.getIdToken().then(token => {
                this.store.updateUser(this.user.uid, token)
            })

        },
        resetPassword() {
            sendPasswordResetEmail(this.auth, this.user.email)
            .then(() => {
                this.error = null
                this.info = 'Password reset email sent'
                // this.$router.push('/login')
            })
            .catch((error) => {
                console.log(error)
                this.error = error.message
                this.info = null
            });
        }

    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
